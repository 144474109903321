import React, { memo, useRef } from 'react';
import { InView } from 'react-intersection-observer';
import { WithInjectedNftMetadata } from '@/shared/components/inject-metadata-hoc/WithInjectedNftMetadata';
import Link from 'next/link';
import { NftSkeletonItem } from '@/shared/components/nfts-grid/NftSkeletonItem';
import { DAS } from 'helius-sdk';
import { AhListedItem } from '@/requests/validation_schemas';
import solanaIconPng from '@/public/images/solana-sol-logo.png';
import Image from 'next/image';
import Badge_1 from '../Badge_1';
import Badge_2 from '../Badge_2';

type NftItemWithCombinedData = DAS.GetAssetResponse & {
    isCollection?: boolean;
    isAhItem?: boolean;
    ahListing?: AhListedItem;
};
interface NftGridProps {
    items?: NftItemWithCombinedData[];
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
    rootClassName?: string;
    showSoldStatus?: boolean;
}

const NftsGrid = memo(
    ({
        items,
        isLoading,
        isError,
        rootClassName = 'grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-4',
        showSoldStatus,
    }: NftGridProps) => {
        // console.log({ isLoading });
        const itemsRootRef = useRef(null);

        return (
            <div className={rootClassName} ref={itemsRootRef}>
                {isLoading
                    ? Array.from({ length: 12 }, (_, index) => {
                          return (
                              <div
                                  className="rouned-2xl h-full dark:bg-jacarta-700 dark:border-jacarta-700 border-jacarta-100 rounded-2.5xl block border bg-white p-[1.1875rem] transition-shadow hover:shadow-lg"
                                  key={index}
                              >
                                  <NftSkeletonItem withText />
                              </div>
                          );
                      })
                    : null}
                {!isLoading && items?.length === 0 && (
                    <div className="text-center my-12 dark:text-white w-full col-span-2 text-xl">No items found</div>
                )}
                {!isLoading &&
                    !isError &&
                    items &&
                    items.map((nftItem) => {
                        // console.log({ nftItem: nftItem.ahListing });
                        return (
                            <article key={nftItem.id}>
                                <div className="h-full dark:bg-jacarta-700 dark:shadow-[0px_0px_10px_4px_rgba(130,88,255,0.4)] rounded-2.5xl border bg-white p-[1.1875rem] transition-shadow hover:shadow-lg rounded-2xl">
                                    <figure className="relative">
                                        <InView triggerOnce={true}>
                                            {({ inView, ref }) => {
                                                return (
                                                    <div ref={ref}>
                                                        {inView ? (
                                                            <WithInjectedNftMetadata
                                                                ref={itemsRootRef}
                                                                metadataUri={nftItem?.content?.json_uri}
                                                                loadingComponent={<NftSkeletonItem />}
                                                            >
                                                                {(metadata) => {
                                                                    return (
                                                                        <Link href={`/item/${nftItem.id || ''}`}>
                                                                            <img
                                                                                width={330}
                                                                                height={330}
                                                                                src={metadata?.image}
                                                                                alt={`Nft name: ${metadata?.name}, Description: ${metadata?.description}`}
                                                                                className="w-full h-[330px] rounded-[0.625rem] object-contain"
                                                                            />
                                                                        </Link>
                                                                    );
                                                                }}
                                                            </WithInjectedNftMetadata>
                                                        ) : null}
                                                    </div>
                                                );
                                            }}
                                        </InView>
                                    </figure>

                                    <div className="flex justify-between pt-6 pb-2">
                                        {nftItem.ahListing?.category ? (
                                            <span className="border-jacarta-600 p-2 rounded-lg bg-jacarta-600 border min-w-[5rem] text-center">
                                                <span
                                                    className={
                                                        'dark:text-white text-accent animate-gradient bg-jacarta-500 rounded-xl inline-block'
                                                    }
                                                >
                                                    {nftItem.ahListing?.category}
                                                </span>
                                            </span>
                                        ) : null}

                                        {nftItem.ahListing?.category === 'Memes' ||
                                        nftItem.ahListing?.category === 'Collectibles' ||
                                        showSoldStatus === false ? ( // de verificat confitia de hasSold - KFC {nftItem.ahListing?.hasSold === true}
                                            <div className="">
                                                <Badge_2 />
                                            </div>
                                        ) : (
                                            <div className="">
                                                <Badge_1 />
                                            </div>
                                        )}
                                        {/* {showSoldStatus === true && nftItem.ahListing?.hasSold === true ? <div className=""></div> : null} */}
                                    </div>

                                    <div className="mt-3 flex items-center justify-between">
                                        <Link href={`/item/${nftItem.id || ''}`}>
                                            <span className="font-display text-jacarta-700 hover:text-accent text-base dark:text-white">
                                                {nftItem?.content?.metadata.name}
                                            </span>
                                        </Link>
                                        {nftItem.ahListing ? (
                                            <div className="flex items-center">
                                                <span className="dark:text-white">
                                                    {nftItem.ahListing.price / Math.pow(10, 9)}
                                                </span>
                                                <Image
                                                    src={solanaIconPng.src}
                                                    width={13}
                                                    height={13}
                                                    className={'mx-1'}
                                                    alt={'Solana icon'}
                                                />
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            </article>
                        );
                    })}
            </div>
        );
    }
);

export default NftsGrid;
