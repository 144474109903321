import React from 'react';

const Badge_1 = () => {
    return (
        <div className="neon">
            <p className="neon-text1">ON SALE</p>
        </div>
    );
};

export default Badge_1;
