import React from 'react';

const Badge_2 = () => {
    return (
        <div className="neon">
            <p className="neon-text">SOLD OUT</p>
        </div>
    );
};

export default Badge_2;
